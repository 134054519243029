import React, { useRef, useEffect } from "react";
import { TfiPrinter  } from "react-icons/tfi";

const PrivacyNotice = ({setShowHowItWorks}) => {
  const iframeRef = useRef(null);
  useEffect(() => {
    // This effect runs after the component has rendered
    setShowHowItWorks(false);
  }, [setShowHowItWorks]);
  const handlePrint = () => {
    const iframe = iframeRef.current;
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.focus(); // Focus the iframe content
      iframe.contentWindow.print(); // Trigger the print dialog
    } else {
      alert("Unable to access iframe content for printing.");
    }
  };

  return (
    <div className="iframe-pages" style={{ position: "relative"}}>
      <iframe
        ref={iframeRef}
        src="/privacy-notice.html"
        title="Privacy Notice"
        style={{
          width: "100%",
          height: "100%",
          border: "none",
        }}
      ></iframe>

      {/* Print Button */}
      <button
        onClick={handlePrint}
        style={{
          position: "absolute",
          right: "10px",
          padding: "16px",
          fontSize: "20px",
          backgroundColor: "#fff",
          color: "#030765",
          border: "none",
          cursor: "pointer",
        }}
        title="Print Privacy Notice"
      >
        <TfiPrinter  />
      </button>
    </div>
  );
};

export default PrivacyNotice;
