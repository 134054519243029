import React, { useCallback, useState } from "react";
import { HashRouter, Route, Routes, Navigate } from "react-router-dom"; // Updated import
import Footer from "./Footer";
import Header from "./Header";
import Home from "./Pages/Home/Home";
import NotFound from "./Pages/Errors/NotFound"; // Import the NotFound component
import QuestionForm from "./Pages/Home/QuestionForm";
import Terms from "./Pages/Terms";
import JoinNewsLetterForm from "./Pages/Home/JoinNewsLetterForm";
import BecomeASupplierForm from "./Pages/Home/BecomeASupplierForm";
import PrivacyNotice from "./Pages/PrivacyNotice";

const App = () => {
  const [showQuestionForm, setShowQuestionForm] = useState(false);
  const [showQuestionKey, setShowQuestionKey] = useState(null);
  const [showHowItWorks, setShowHowItWorks] = useState(true);
  const handleQuestionFormShow = useCallback((key) => {
    setShowQuestionForm(true);
    setShowQuestionKey(key);
  },[]);

  // Function to update URL
  const updateUrlParameter = useCallback((param, value, fragment = 'Home') => {
    let url = new URL(window.location.href);
    let newUrl = `${url.origin}${url.pathname}`;

    // Set the hash part of the URL first
    newUrl += `#${fragment}`;

    // Prepare the query string
    let searchParams = new URLSearchParams(url.search);
    searchParams.set(param, value);
    let queryString = searchParams.toString();

    // If there are query parameters, append them after the hash
    if (queryString) {
      newUrl += `?${queryString}`;
    }

    // Update the URL without reloading the page
    window.history.pushState({}, '', newUrl);
  },[]);

  const handleQuestionFormClose = useCallback((shouldClearUrl = true) => {
    setShowQuestionForm(false);
    setShowQuestionKey(null);
    if (shouldClearUrl) {
      updateUrlParameter('menuId', '');
    }
  },[updateUrlParameter]);

  const [showJoinNewsLetterForm, setShowJoinNewsLetterForm] = useState(false);
  const handleJoinNewsLetterFormShow = useCallback(() => setShowJoinNewsLetterForm(true),[]);

  const [showBecomeASupplierForm, setShowBecomeASupplierForm] = useState(false);
  const handleBecomeASupplierFormShow = useCallback(() => setShowBecomeASupplierForm(true),[]);

  return (
    <HashRouter> {/* Changed to HashRouter */}
      <Header onAskQuestion={handleQuestionFormShow} handleJoinNewsLetterFormShow={handleJoinNewsLetterFormShow} handleBecomeASupplierFormShow={handleBecomeASupplierFormShow} />
      <Routes>
        <Route path="Home" element={
          <Home 
            setShowHowItWorks={setShowHowItWorks} 
            handleQuestionShow={handleQuestionFormShow} 
            handleQuestionFormClose={handleQuestionFormClose} 
            updateUrlParameter={updateUrlParameter}
            handleJoinNewsLetterFormShow={handleJoinNewsLetterFormShow} 
            handleBecomeASupplierFormShow={handleBecomeASupplierFormShow}/>
          } 
        />
        <Route path="/" element={<Navigate to="Home" />} /> {/* Redirect root to Home */}
        <Route path="/Terms" element={<Terms setShowHowItWorks={setShowHowItWorks} />} />
        <Route path="/Privacy" element={<PrivacyNotice setShowHowItWorks={setShowHowItWorks} />} />
        <Route path="*" element={<NotFound />} /> {/* Catch-all route for 404 */}
      </Routes>
      <Footer showHowItWorks={showHowItWorks} />
      <QuestionForm show={showQuestionForm} handleQuestionFormClose={handleQuestionFormClose} showQuestionKey={showQuestionKey} />
      <JoinNewsLetterForm show={showJoinNewsLetterForm} setJoinNews={setShowJoinNewsLetterForm} />
      <BecomeASupplierForm showSupplier={showBecomeASupplierForm} setShowSupplier={setShowBecomeASupplierForm} />
    </HashRouter>
  );
};

export default App;