import React, { useState } from "react";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import ApiHost from "../../ApiHost";

const JoinNewsLetterFrom = ({ show, setJoinNews }) => {
  const [formData, setFormData] = useState({
    email: "",
    first_name: "",
    last_name: "",
    reference: "",
    consent: false
  });

  const resetForm = () => {
    setFormData({
      email: "",
      first_name: "",
      last_name: "",
      reference: "",
      consent: false
    });
  };

  const [newsLoading, setNewsLoading] = useState(false);
  const handleNewsClose = () => {
    setJoinNews(false);
    setNewsLoading(false);
  };

  const handleChange = (e) => {
    const { id, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    setNewsLoading(true);
    e.preventDefault();

    try {
      const response = await fetch(`${ApiHost}/v1/submit-join-news-letter-form`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
  
      if (!response.ok) throw new Error('Failed to submit news');

      handleNewsClose();
      resetForm();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <Modal show={show} onHide={handleNewsClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Join our exclusive community</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="email">
            <Form.Control
              type="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <br />
          <Form.Group controlId="first_name">
            <Form.Control
              type="text"
              placeholder="First name"
              value={formData.first_name}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <br />
          <Form.Group controlId="last_name">
            <Form.Control
              type="text"
              placeholder="Last name"
              value={formData.last_name}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <br />
          <Form.Group controlId="reference">
            <Form.Control
              type="text"
              placeholder="Where did you hear about us?"
              value={formData.reference}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <br />
          <Form.Group controlId="consent">
            <Form.Check
              type="checkbox"
              label="I consent to using my details to create a DriverHQ profile."
              checked={formData.consent}
              onChange={handleChange}
              required
            />
            <br />
            <p>
              For further details, please refer to our{" "}
              <a href="/Privacy">Privacy Policy</a>.
            </p>
          </Form.Group>
          <Button variant="warning" type="submit" style={{ width: "100%" }} disabled={newsLoading}>
                    {newsLoading ? (
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    ) : (
                        'Submit'
                    )}
                </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default JoinNewsLetterFrom;
