import React from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { IoArrowBack } from "react-icons/io5";

const MenuModal = ({
  showMenu = () => {},
  currentMenu = {},
  currentVertical = {},
  handleMenuClose = () => {},
  handleOptionClick = () => {}
}) => {
  const handleGoBack = () => {
    window.history.back();
  };
  return (
    <Modal show={showMenu} onHide={() => handleMenuClose(true)} size="lg" centered>
      <Modal.Header style={{ justifyContent: 'space-between' }} closeButton>
        <IoArrowBack 
          style={{ cursor: 'pointer', flexShrink: 0 }}
          size={26}
          onClick={handleGoBack}
        />
        <div style={{ flexGrow: 1, textAlign: 'center' }}>
          <Modal.Title className="ms-auto">{currentMenu?.title}</Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
        {currentMenu?.content ? (
          <p>{currentMenu.content}</p>
        ) : (
          <div id="htmlContentMenuBox">
            <div dangerouslySetInnerHTML={{ __html: currentMenu?.html_content }} />
          </div>
        )}
        {currentMenu?.options && currentMenu.options.length > 0 && (
          <Row className="d-flex justify-content-center">
            {currentMenu.options.map((option, index) => (
              <Col
                key={index}
                md={currentMenu.layout === "horizontal" ? 4 : 12}
                className="d-flex justify-content-center"
                style={{ marginBottom: "25px" }}
              >
                <Button
                  variant="warning"
                  style={{
                    width: currentMenu.layout === "horizontal" ? "100%" : "80%",
                    padding: "10px",
                  }}
                  onClick={() => handleOptionClick(option.key, currentMenu.sub_menus?.[option.key]?.is_function, currentVertical.functions)}
                >
                  {option.value}
                </Button>
              </Col>
            ))}
          </Row>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default MenuModal;