import React, { useState } from "react";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import ApiHost from "../../ApiHost";

const BecomeASupplierForm = ({ showSupplier, setShowSupplier }) => {
  const [formData, setFormData] = useState({
    description: "",
    email: "",
    company_name: "",
    website: "",
    phone: ""
  });

  const resetForm = () => {
    setFormData({
      description: "",
      email: "",
      company_name: "",
      website: "",
      phone: ""
    });
  };

  const [supplierLoading, setSupplierLoading] = useState(false);
  const handleSupplierClose = () => {
    setShowSupplier(false);
    setSupplierLoading(false);
  };

  const handleChange = (e) => {
    const { id, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    setSupplierLoading(true);
    e.preventDefault();

    try {
      const response = await fetch(`${ApiHost}/v1/submit-become-a-supplier-form`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) throw new Error('Failed to submit supplier');

      handleSupplierClose();
      resetForm();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <Modal show={showSupplier} onHide={handleSupplierClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Apply to become part of our network</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="description">
            <Form.Control
              as="textarea"
              required
              rows={3}
              placeholder="Please describe yourself"
              value={formData.description}
              onChange={handleChange}
            />
          </Form.Group>
          <br />
          <Form.Group controlId="email">
            <Form.Control
              type="email"
              required
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
            />
          </Form.Group>
          <br />
          <Form.Group controlId="company_name">
            <Form.Control
              type="text"
              required
              placeholder="Company Name"
              value={formData.company_name}
              onChange={handleChange}
            />
          </Form.Group>
          <br />
          <Form.Group controlId="website">
            <Form.Control
              type="text"
              required
              placeholder="Website"
              value={formData.website}
              onChange={handleChange}
            />
          </Form.Group>
          <br />
          <Form.Group controlId="phone">
            <Form.Control
              required
              type="tel"
              placeholder="Enter your phone number"
              value={formData.phone}
              onChange={handleChange}
            />
          </Form.Group>
          <br/>
          <Button variant="warning" type="submit" style={{ width: "100%" }} disabled={supplierLoading}>
            {supplierLoading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              'Submit'
            )}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default BecomeASupplierForm;
