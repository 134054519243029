import React, { useState, useEffect, useCallback } from "react";
import { Container, Row, Col, Card, Spinner, Alert } from "react-bootstrap";
import NewsCarousel from "./NewsCarousel";
import MenuModal from "./MenuModal";
import useFetchData from "../../hooks/useFetchData"; // Import custom hook
import MarketplaceHeader from "./MarketplaceHeader";
import SupplierSearchModal from '../Suppliers/SupplierSearchPageModal';
import { useSearchParams } from 'react-router-dom';

const Home = ({ handleQuestionShow, setShowHowItWorks, handleQuestionFormClose, updateUrlParameter, handleJoinNewsLetterFormShow, handleBecomeASupplierFormShow }) => {
  // State for managing menu modal visibility and current menu
  useEffect(() => {
    // This effect runs after the component has rendered
    setShowHowItWorks(true);
  }, [setShowHowItWorks]);
  const [showMenu, setShowMenu] = useState(false);
  const [currentMenu, setCurrentMenu] = useState(null);
  const [showSearchProviderModalModal, setSearchProviderModalShowModal] = useState(false);
  const [searchParameters, setSearchParameters] = useState(null);
  const [searchProviderData, setSearchProviderData] = useState(null);
  const [currentVertical, setCurrentVertical] = useState(null);
  const handleSearchProviderModalShow = () => setSearchProviderModalShowModal(true);
  const handleSearchProviderModalClose = useCallback((shouldClearUrl = true) => {
    setSearchProviderModalShowModal(false);
    if (shouldClearUrl) {
      updateUrlParameter('menuId', '');
    }
  },[updateUrlParameter]);
  const [searchParams] = useSearchParams();
  // Fetch verticals and newsItems from the custom hook
  const { verticals, newsItems, loading, error } = useFetchData();

  // Functions for menu management
  const handleMenuShow = (card) => {
    setCurrentMenu(card);
    setShowMenu(true);
    setCurrentVertical(card);
  };

  const handleSearchSuppliers = useCallback(async (parameters, data) => {
    setSearchParameters(JSON.stringify(parameters))
    setSearchProviderData(data);
    handleSearchProviderModalShow();
  }, []);

  const findMenuObject = useCallback((data, keyToFind) => {
    if (data.key === keyToFind) {
      return data;
    }

    if (data.sub_menus) {
      for (let subMenuKey in data.sub_menus) {
        const subMenu = data.sub_menus[subMenuKey];
        const found = findMenuObject(subMenu, keyToFind);
        if (found) return found;
      }
    }

    return null;
  }, []);

  const handleMenuClose = useCallback((shouldClearUrl) => {
    setShowMenu(false);
    if (shouldClearUrl) {
      updateUrlParameter('menuId', '');
    }
    setTimeout(() => { // Introduce a slight delay
      setCurrentMenu(null);
    }, 300);
  },[updateUrlParameter]);

  useEffect(() => {
    const handleUrlChange = () => {
      if (!loading) { // Only proceed if loading is complete
        let menuId = searchParams.get('menuId');
        if (menuId) {
          menuId = decodeURIComponent(atob(menuId));
          let rootMenuId = menuId.split('|')[0];
          let vertical = verticals.find(v => v.key === rootMenuId);
          let menu = findMenuObject(vertical, menuId);

          if (menu) {
            if (menu.is_function) {
              handleMenuClose(false);
              if (menu.key && menu.key.includes("suppliers")) {
                const func = vertical.functions.search_suppliers_functions.find(obj => obj.button_id === menu.key);
                handleSearchSuppliers(func.parameters, func.data);
              } else if (menu.key && menu.key.includes("question")) {
                handleQuestionShow(menu.key);
              }
            } else {
              handleSearchProviderModalClose(false);
              handleQuestionFormClose(false);
              handleMenuShow(menu);
              setCurrentVertical(vertical);
            }
          }
        } else {
          handleMenuClose(false);
          handleSearchProviderModalClose(false);
          handleQuestionFormClose(false);
          let action = searchParams.get('action');
          let source = searchParams.get('linkSource');
          if (action) {
            switch (action) {
              case "question":
                handleQuestionShow(source || 'unknown');
                break;
            
              case "join_news_letter":
                handleJoinNewsLetterFormShow();
                break;

              case "become_a_supplier":
                handleBecomeASupplierFormShow();
                break; 

              default:
                break;
            }
          }
        }
      }
    };

    handleUrlChange();
    // Set up listener for history changes, like back button clicks
    const handlePopState = (event) => {
      handleUrlChange();
    };

    // Add event listener for popstate event
    window.addEventListener('popstate', handlePopState);

    // Clean up function to remove the listener when component unmounts
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [loading, searchParams, verticals, findMenuObject, handleSearchSuppliers, handleQuestionFormClose, handleQuestionShow, updateUrlParameter, handleSearchProviderModalClose, handleMenuClose, handleJoinNewsLetterFormShow, handleBecomeASupplierFormShow]);

  const handleOptionClick = (key, isFunction, functions) => {
    const scrollableElement = document.getElementById('htmlContentMenuBox');

    if (scrollableElement) {
      scrollableElement.scrollTop = 0; // Reset vertical scroll
      scrollableElement.scrollLeft = 0; // Reset horizontal scroll (if needed)
    }

    if (isFunction) {
      handleMenuClose(false); // Close the menu modal first
      if (key && key.includes("suppliers")) {
        const func = functions.search_suppliers_functions.find(obj => obj.button_id === key);
        handleSearchSuppliers(func.parameters, func.data);
      } else if (key && key.includes("question")) {
        handleQuestionShow(key);
      }
    } else {
      const nextMenu = currentMenu.sub_menus?.[key]; // Check if this option has a submenu
      if (nextMenu) {
        setCurrentMenu(nextMenu); // Pass the submenu object directly
      }
    }
    updateUrlParameter('menuId', btoa(encodeURIComponent(key)));
  };

  // Show spinner while loading
  if (loading) {
    return (
      <Container className="text-center my-5">
        <MarketplaceHeader />
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  // Show error message if there's an error
  if (error) {
    return (
      <Container className="text-center my-5">
        <MarketplaceHeader />
        <Alert variant="danger">
          <strong>Oops!</strong> Something went wrong. Unable to load data.
          Please try again later.
        </Alert>
      </Container>
    );
  }

  return (
    <>
      <Container className="my-5 text-center">
        <MarketplaceHeader />
        <Row>
          {verticals
            .sort((a, b) => a.order - b.order)
            .map((card) => (
              <Col key={card.key} md={2}>
                <Card
                  className="p-6"
                  style={{
                    borderRadius: "25px",
                    border: "0",
                    color: "#030765",
                  }}
                  onClick={() => { handleMenuShow(card); updateUrlParameter('menuId', btoa(encodeURIComponent(card.key))); }}
                >
                  <Card.Img
                    variant="top"
                    src={card.icon_url}
                    style={{
                      width: "100%",
                      height: "150px",
                      objectFit: "cover",
                      borderRadius: "25px",
                    }}
                  />
                  <Card.Body>
                    <Card.Title>{card.title}</Card.Title>
                  </Card.Body>
                </Card>
              </Col>
            ))}
        </Row>
        <Row>
          <NewsCarousel news={newsItems} handleMenuShow={handleMenuShow} />
        </Row>
      </Container>

      {/* Menu Modal */}
      <MenuModal
        showMenu={showMenu}
        currentMenu={currentMenu}
        currentVertical={currentVertical}
        handleMenuClose={handleMenuClose}
        handleOptionClick={handleOptionClick}
      />
      <SupplierSearchModal
        show={showSearchProviderModalModal}
        handleClose={handleSearchProviderModalClose}
        parameters={searchParameters}
        providerData={searchProviderData}
      />
    </>
  );
};

export default Home;