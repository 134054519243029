import React from "react";
import { Row, Col, Button } from "react-bootstrap";

const Header = ({ onAskQuestion, handleJoinNewsLetterFormShow, handleBecomeASupplierFormShow }) => {
  return (
    <div
      className="header-background text-white text-center py-3"
      style={{ width: "100%", margin: 0 }}
    >
      <div className="logo">
        <img alt="logo" src="https://cdn.driverhq.co.uk/images/78f5b28e6cfc86cfd12cccba5ffb5246b4a70d71b446ae5609bc8cca670431c3.svg"/>
      </div>

      {/* Ask a Question Button */}
      <Row
        className="d-flex flex-wrap justify-content-center no-gutters"
        style={{ marginLeft: "0", marginTop: "2em", maxWidth: "100%" }}
      >
        <Col
          md="auto"
          className="d-flex justify-content-center"
          style={{ marginBottom: "1em" }}
        >
          <Button variant="warning" onClick={() => onAskQuestion("home|ask_us_a_question")}>
            Ask a question
          </Button>
        </Col>

        <Col
          md="auto"
          className="d-flex justify-content-center"
          style={{ marginBottom: "1em" }}
        >
          <Button className="text-light" onClick={handleJoinNewsLetterFormShow} variant="success">Join our newsletter</Button>
        </Col>

        <Col
          md="auto"
          className="d-flex justify-content-center"
          style={{ marginBottom: "1em" }}
        >
          <Button onClick={handleBecomeASupplierFormShow} variant="light">Become a supplier</Button>
        </Col>
      </Row>
    </div>
  );
};

export default Header;